import * as $ from 'jquery'
import 'bootstrap'
import 'owl.carousel';
import '@fancyapps/fancybox'
import './js/app'

// SCSS
import './assets/sass/app.sass'
import './assets/sass/media.sass'

